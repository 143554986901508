@font-face {
  font-family: 'KoPubWorldDotum';
  src: url('../assets/fonts/KoPubWorld-Dotum_Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'KoPubWorldDotum';
  src: url('../assets/fonts/KoPubWorld-Dotum_Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'KoPubWorldDotum';
  src: url('../assets/fonts/KoPubWorld-Dotum_Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'SCDream';
  src: url('../assets/fonts/SCDream5.otf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'SCDream';
  src: url('../assets/fonts/SCDream6.otf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'SCDream';
  src: url('../assets/fonts/SCDream8.otf') format('truetype');
  font-weight: 800;
}
